import * as React from 'react';
import loadable from '@loadable/component';
import 'aos/dist/aos.css';
import { sectionList, FormTypes } from '../../constants/queryFragments';
import { graphql } from 'gatsby';
import Cookies from 'universal-cookie';
import GlobalStyles from '../../styles/slpGlobalStyles';
import LanguageModal from '../../components/languageModal/languageModal';
import { Helmet } from 'react-helmet';
import {
  addTrailingSlash,
  isValidURLLanguage,
  validNodeLocale,
} from '../../utils';
import ogLogo from '../../images/og-logo.jpg';

const StandalonePageTemplate = ({ data, location }) => {
  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  const englishSlug = data?.allContentfulUltramarCommercial?.edges?.find(
    (edge) => edge?.node?.node_locale === 'en-US'
  )?.node?.slug;

  const frenchSlug = data?.allContentfulUltramarCommercial?.edges?.find(
    (edge) => edge?.node?.node_locale === 'fr-CA'
  )?.node?.slug;

  const languageFilteredData =
    data?.allContentfulUltramarCommercial?.edges.find((edge) =>
      isValidURLLanguage(urlLanguage)
        ? edge?.node?.node_locale === validNodeLocale(urlLanguage)
        : edge?.node?.node_locale === 'en-US'
    );
  const sectionsArray = languageFilteredData?.node?.sections;
  return !loaded ? (
    <>
      <Helmet>
        <title>
          {languageFilteredData?.node?.title
            ? languageFilteredData?.node?.title + ' | Ultramar'
            : 'Energy Solutions for Residential and Businesses | Ultramar'}
        </title>
        {englishSlug && (
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + englishSlug)}
            hreflang="en-ca"
          ></link>
        )}
        {frenchSlug && (
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + frenchSlug)}
            hreflang="fr-ca"
          ></link>
        )}
        {languageFilteredData?.node?.metadata && (
          <meta
            name="description"
            content={languageFilteredData?.node?.metadata}
          ></meta>
        )}
        {languageFilteredData?.node?.hasIndex === false && (
          <meta name="robots" content="noindex" />
        )}
        <meta property="og:image" content={ogLogo}></meta>
        <meta property="twitter:image" content={ogLogo}></meta>
      </Helmet>
      <GlobalStyles />
      {sectionsArray &&
        sectionsArray.map((section, index) => {
          if (
            section?.type &&
            sectionList?.includes(section?.type) &&
            !FormTypes?.includes(section?.type)
          ) {
            const SectionComponent = loadable(() =>
              import(`../../components/${section.type}/${section.type}`)
            );
            return (
              <SectionComponent
                key={index}
                sectionData={section}
                urlLanguage={urlLanguage}
              />
            );
          }
          return <></>;
        })}
    </>
  ) : (
    <>
      <Helmet>
        <title>
          {languageFilteredData?.node?.title
            ? languageFilteredData?.node?.title + ' | Ultramar'
            : 'Energy Solutions for Residential and Businesses | Ultramar'}
        </title>
        {englishSlug && (
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + englishSlug)}
            hreflang="en-ca"
          ></link>
        )}
        {frenchSlug && (
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + frenchSlug)}
            hreflang="fr-ca"
          ></link>
        )}
        {languageFilteredData?.node?.metadata && (
          <meta
            name="description"
            content={languageFilteredData?.node?.metadata}
          ></meta>
        )}
        {languageFilteredData?.node?.hasIndex === false && (
          <meta name="robots" content="noindex" />
        )}
        <meta property="og:image" content={ogLogo}></meta>
        <meta property="twitter:image" content={ogLogo}></meta>
      </Helmet>
      <GlobalStyles />
      <>
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (section?.type && sectionList.includes(section?.type)) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section.type}/${section.type}`)
              );
              return (
                <SectionComponent
                  key={index}
                  sectionData={section}
                  urlLanguage={urlLanguage}
                />
              );
            }
            return <></>;
          })}
      </>
    </>
  );
};

export default StandalonePageTemplate;

export const pageQuery = graphql`
  query allStandalonePagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulContentList: Boolean!
    $contentfulImageWithPath: Boolean!
    $contentfulImageContainer: Boolean! # $contentfulMultimediaText: Boolean! # $contentfulListImagesWithPath: Boolean!
  ) {
    allContentfulUltramarCommercial(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          metadata
          hasIndex
          title
          node_locale
          sections {
            __typename
            ...ImageWithPath @include(if: $contentfulImageWithPath)
            ...ImageContainer @include(if: $contentfulImageContainer)
            ...PageContent @include(if: $contentfulContent)
            ...ContentList @include(if: $contentfulContentList)
          }
        }
      }
    }
  }
`;
